/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HomeMatchList } from '../models/HomeMatchList';
import type { LeagueMatchList } from '../models/LeagueMatchList';
import type { Match } from '../models/Match';
import type { MatchLeague } from '../models/MatchLeague';
import type { MatchLeagueCreate } from '../models/MatchLeagueCreate';
import type { MatchLeagueCreateNew } from '../models/MatchLeagueCreateNew';
import type { MatchLeaguePatch } from '../models/MatchLeaguePatch';
import type { MatchListElementList } from '../models/MatchListElementList';
import type { MatchSmallList } from '../models/MatchSmallList';
import type { MatchTournamentGroup } from '../models/MatchTournamentGroup';
import type { MatchTournamentGroupCreate } from '../models/MatchTournamentGroupCreate';
import type { MatchTournamentGroupPatch } from '../models/MatchTournamentGroupPatch';
import type { MatchTournamentStage } from '../models/MatchTournamentStage';
import type { MatchTournamentStageCreate } from '../models/MatchTournamentStageCreate';
import type { MatchTournamentStagePatch } from '../models/MatchTournamentStagePatch';
import type { MatchTypeUnion } from '../models/MatchTypeUnion';
import type { ShapeComparison } from '../models/ShapeComparison';
import type { SportEnum } from '../models/SportEnum';
import type { Task } from '../models/Task';
import type { Translation } from '../models/Translation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MatchService {
    /**
     * @deprecated
     * Get League Match By Date
     * @param date
     * @param areaOfInterest
     * @param sports
     * @returns LeagueMatchList Successful Response
     * @throws ApiError
     */
    public static getLeagueMatchByDateLeagueMatchesDateGet(
        date: string,
        areaOfInterest: number,
        sports: SportEnum,
    ): CancelablePromise<LeagueMatchList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/league_matches/date',
            query: {
                'date': date,
                'area_of_interest': areaOfInterest,
                'sports': sports,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Get Match
     * @param matchId
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static getMatchMatchMatchIdGet(
        matchId: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Patch League Match
     * @param matchId
     * @param requestBody
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static patchLeagueMatchMatchMatchIdPatch(
        matchId: number,
        requestBody: MatchLeaguePatch,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Delete Match
     * @param matchId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteMatchMatchMatchIdDelete(
        matchId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Get Matches By Team
     * @param teamId
     * @param limit
     * @param playedOnly
     * @param notCancelledOnly
     * @param mainOnly
     * @returns MatchListElementList Successful Response
     * @throws ApiError
     */
    public static getMatchesByTeamMatchTeamTeamIdGet(
        teamId: number,
        limit: number,
        playedOnly: boolean,
        notCancelledOnly: boolean = false,
        mainOnly: boolean = false,
    ): CancelablePromise<MatchListElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/match/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'limit': limit,
                'played_only': playedOnly,
                'not_cancelled_only': notCancelledOnly,
                'main_only': mainOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Get Matches By League And Date
     * @param leagueId
     * @param matchDate
     * @returns MatchListElementList Successful Response
     * @throws ApiError
     */
    public static getMatchesByLeagueAndDateMatchLeagueLeagueIdDateMatchDateGet(
        leagueId: number,
        matchDate: string,
    ): CancelablePromise<MatchListElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/match/league/{league_id}/date/{match_date}',
            path: {
                'league_id': leagueId,
                'match_date': matchDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Get Matches By League And Match Day
     * @param leagueId
     * @param matchDay
     * @returns MatchListElementList Successful Response
     * @throws ApiError
     */
    public static getMatchesByLeagueAndMatchDayMatchLeagueLeagueIdMatchDayMatchDayGet(
        leagueId: number,
        matchDay: number,
    ): CancelablePromise<MatchListElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/match/league/{league_id}/match_day/{match_day}',
            path: {
                'league_id': leagueId,
                'match_day': matchDay,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Get Match History
     * @param matchId
     * @param limit
     * @returns MatchListElementList Successful Response
     * @throws ApiError
     */
    public static getMatchHistoryMatchHistoryMatchIdGet(
        matchId: number,
        limit: number,
    ): CancelablePromise<MatchListElementList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/match/history/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Create Match
     * @param requestBody
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static createMatchMatchPost(
        requestBody: MatchLeagueCreateNew,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/match/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Create Multiple Matches
     * @param requestBody
     * @returns Match Successful Response
     * @throws ApiError
     */
    public static createMultipleMatchesMatchMultiplePost(
        requestBody: Array<MatchLeagueCreate>,
    ): CancelablePromise<Array<Match>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/match/multiple',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Start Match
     * @param matchId
     * @param minutes
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static startMatchMatchStartMatchIdPost(
        matchId: number,
        minutes: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/match/start/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'minutes': minutes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Cancel Match
     * @param matchId
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static cancelMatchMatchCancelMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/match/cancel/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Uncancel Match
     * @param matchId
     * @param newStartTime
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static uncancelMatchMatchUncancelMatchIdPost(
        matchId: number,
        newStartTime: (string | null),
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/match/uncancel/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'new_start_time': newStartTime,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Set Match Report
     * @param matchId
     * @param report
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static setMatchReportMatchReportMatchIdPut(
        matchId: number,
        report: string,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/match/report/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'report': report,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Add Minutes
     * @param matchId
     * @param minutes
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static addMinutesMatchAddMatchIdMinutesPost(
        matchId: number,
        minutes: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/match/add/{match_id}/{minutes}',
            path: {
                'match_id': matchId,
                'minutes': minutes,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Finish Match
     * @param matchId
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static finishMatchMatchFinishMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/match/finish/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Get Random Match Id
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getRandomMatchIdMatchRandomGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/match/random',
        });
    }
    /**
     * Get Match League
     * @param matchId
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static getMatchLeagueMatchesV2LeagueMatchIdGet(
        matchId: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch League Match
     * @param matchId
     * @param requestBody
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static patchLeagueMatchMatchesV2LeagueMatchIdPatch(
        matchId: number,
        requestBody: MatchLeaguePatch,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/matches/v2/league/{match_id}',
            path: {
                'match_id': matchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Match Tournament Group
     * @param matchId
     * @returns MatchTournamentGroup Successful Response
     * @throws ApiError
     */
    public static getMatchTournamentGroupMatchesV2TournamentGroupMatchIdGet(
        matchId: number,
    ): CancelablePromise<MatchTournamentGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/tournament/group/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Tournament Group Match
     * @param matchId
     * @param requestBody
     * @returns MatchTournamentGroup Successful Response
     * @throws ApiError
     */
    public static patchTournamentGroupMatchMatchesV2TournamentGroupMatchIdPatch(
        matchId: number,
        requestBody: MatchTournamentGroupPatch,
    ): CancelablePromise<MatchTournamentGroup> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/matches/v2/tournament/group/{match_id}',
            path: {
                'match_id': matchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Match Tournament Stage
     * @param matchId
     * @returns MatchTournamentStage Successful Response
     * @throws ApiError
     */
    public static getMatchTournamentStageMatchesV2TournamentStageMatchIdGet(
        matchId: number,
    ): CancelablePromise<MatchTournamentStage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/tournament/stage/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Patch Tournament Stage Match
     * @param matchId
     * @param requestBody
     * @returns MatchTournamentStage Successful Response
     * @throws ApiError
     */
    public static patchTournamentStageMatchMatchesV2TournamentStageMatchIdPatch(
        matchId: number,
        requestBody: MatchTournamentStagePatch,
    ): CancelablePromise<MatchTournamentStage> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/matches/v2/tournament/stage/{match_id}',
            path: {
                'match_id': matchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Change Team League Match
     * @param matchId
     * @param teamFromId
     * @param teamToId
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static changeTeamLeagueMatchMatchesV2LeagueChangeTeamMatchIdPost(
        matchId: number,
        teamFromId: number,
        teamToId: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/league/change_team/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'team_from_id': teamFromId,
                'team_to_id': teamToId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Switch Team League Match
     * @param matchId
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static switchTeamLeagueMatchMatchesV2LeagueSwitchTeamsMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/league/switch_teams/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Match League
     * @param leagueId
     * @param requestBody
     * @returns MatchLeague Successful Response
     * @throws ApiError
     */
    public static createMatchLeagueMatchesV2LeagueLeagueIdPost(
        leagueId: number,
        requestBody: MatchLeagueCreateNew,
    ): CancelablePromise<MatchLeague> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/league/{league_id}',
            path: {
                'league_id': leagueId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Match Tournament Group
     * @param tournamentGroupId
     * @param requestBody
     * @returns MatchTournamentGroup Successful Response
     * @throws ApiError
     */
    public static createMatchTournamentGroupMatchesV2GroupTournamentGroupIdPost(
        tournamentGroupId: number,
        requestBody: MatchTournamentGroupCreate,
    ): CancelablePromise<MatchTournamentGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/group/{tournament_group_id}',
            path: {
                'tournament_group_id': tournamentGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Create Match Tournament Stage
     * @param tournamentStageId
     * @param requestBody
     * @returns MatchTournamentStage Successful Response
     * @throws ApiError
     */
    public static createMatchTournamentStageMatchesV2StageTournamentStageIdPost(
        tournamentStageId: number,
        requestBody: MatchTournamentStageCreate,
    ): CancelablePromise<MatchTournamentStage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/stage/{tournament_stage_id}',
            path: {
                'tournament_stage_id': tournamentStageId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matches By Team
     * @param teamId
     * @param limit
     * @param playedOnly
     * @param notCancelledOnly
     * @param mainOnly
     * @param tournamentId
     * @returns MatchSmallList Successful Response
     * @throws ApiError
     */
    public static getMatchesByTeamMatchesV2TeamTeamIdGet(
        teamId: number,
        limit: number,
        playedOnly: boolean,
        notCancelledOnly: boolean = false,
        mainOnly: boolean = false,
        tournamentId?: number,
    ): CancelablePromise<MatchSmallList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/team/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'limit': limit,
                'played_only': playedOnly,
                'not_cancelled_only': notCancelledOnly,
                'main_only': mainOnly,
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matches By League And Date
     * @param leagueId
     * @param matchDate
     * @returns MatchSmallList Successful Response
     * @throws ApiError
     */
    public static getMatchesByLeagueAndDateMatchesV2LeagueLeagueIdDateMatchDateGet(
        leagueId: number,
        matchDate: string,
    ): CancelablePromise<MatchSmallList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/league/{league_id}/date/{match_date}',
            path: {
                'league_id': leagueId,
                'match_date': matchDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matches By League And Match Day
     * @param leagueId
     * @param matchDay
     * @returns MatchSmallList Successful Response
     * @throws ApiError
     */
    public static getMatchesByLeagueAndMatchDayMatchesV2LeagueLeagueIdMatchDayMatchDayGet(
        leagueId: number,
        matchDay: number,
    ): CancelablePromise<MatchSmallList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/league/{league_id}/match_day/{match_day}',
            path: {
                'league_id': leagueId,
                'match_day': matchDay,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Match History
     * @param matchId
     * @param limit
     * @returns MatchSmallList Successful Response
     * @throws ApiError
     */
    public static getMatchHistoryMatchesV2HistoryMatchIdGet(
        matchId: number,
        limit: number,
    ): CancelablePromise<MatchSmallList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/history/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Delete Matches
     * @param requestBody
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static bulkDeleteMatchesMatchesV2BulkDelete(
        requestBody: Array<number>,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/matches/v2/bulk',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Bulk Switch Teams
     * @param requestBody
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static bulkSwitchTeamsMatchesV2BulkSwitchTeamsPost(
        requestBody: Array<number>,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/bulk/switch_teams',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Delete Match
     * @param matchId
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteMatchMatchesV2MatchIdDelete(
        matchId: number,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/matches/v2/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Matches By Tournament
     * @param tournamentId
     * @returns MatchSmallList Successful Response
     * @throws ApiError
     */
    public static getMatchesByTournamentMatchesV2TournamentTournamentIdGet(
        tournamentId: number,
    ): CancelablePromise<MatchSmallList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/tournament/{tournament_id}',
            path: {
                'tournament_id': tournamentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Home Matches
     * @param areaOfInterestId
     * @param date
     * @param sports
     * @returns HomeMatchList Successful Response
     * @throws ApiError
     */
    public static getHomeMatchesMatchesV2HomeGet(
        areaOfInterestId: number,
        date: string,
        sports: SportEnum,
    ): CancelablePromise<HomeMatchList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/home',
            query: {
                'area_of_interest_id': areaOfInterestId,
                'date': date,
                'sports': sports,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get My Matches
     * @param limit
     * @param offset
     * @returns MatchSmallList Successful Response
     * @throws ApiError
     */
    public static getMyMatchesMatchesV2MyGet(
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<MatchSmallList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/my',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Kickoff League Match
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static kickoffLeagueMatchMatchesV2KickoffLeagueMatchLeagueMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/kickoff_league_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reverse Kickoff League Match
     * @param matchId
     * @param originalTime
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static reverseKickoffLeagueMatchMatchesV2ReverseKickoffMatchLeagueMatchIdPost(
        matchId: number,
        originalTime: string,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/reverse_kickoff_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'original_time': originalTime,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Set Match Minute
     * @param matchId
     * @param minute
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static setMatchMinuteMatchesV2SetMatchMinuteMatchIdMinutePut(
        matchId: number,
        minute: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/matches/v2/set_match_minute/{match_id}/{minute}',
            path: {
                'match_id': matchId,
                'minute': minute,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Stop First Half Match League
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static stopFirstHalfMatchLeagueMatchesV2StopFirstHalfMatchLeagueMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/stop_first_half_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reverse Stop First Half Match League
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static reverseStopFirstHalfMatchLeagueMatchesV2ReverseStopFirstHalfMatchLeagueMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/reverse_stop_first_half_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Second Half Match League
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static startSecondHalfMatchLeagueMatchesV2StartSecondHalfMatchLeagueMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/start_second_half_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Reverse Second Half Match League
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static reverseSecondHalfMatchLeagueMatchesV2ReverseSecondHalfMatchLeagueMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/reverse_second_half_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Stop Match League
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static stopMatchLeagueMatchesV2StopMatchLeagueMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/stop_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Stop Match League
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static stopMatchLeagueMatchesV2ReverseStopMatchLeagueMatchIdPost(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/matches/v2/reverse_stop_match_league/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Head To Head
     * @param leagueId
     * @param team1Id
     * @param team2Id
     * @param beforeMatchId
     * @param limit
     * @returns ShapeComparison Successful Response
     * @throws ApiError
     */
    public static getHeadToHeadMatchesV2ShapeComparisonGet(
        leagueId: number,
        team1Id: number,
        team2Id: number,
        beforeMatchId: number,
        limit: number,
    ): CancelablePromise<ShapeComparison> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/shape_comparison',
            query: {
                'league_id': leagueId,
                'team_1_id': team1Id,
                'team_2_id': team2Id,
                'before_match_id': beforeMatchId,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Load Match Board
     * @param matchId
     * @param teamId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static loadMatchBoardMatchesV2LoadMatchBoardMatchIdTeamIdPut(
        matchId: number,
        teamId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/matches/v2/load_match_board/{match_id}/{team_id}',
            path: {
                'match_id': matchId,
                'team_id': teamId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Unload Match Board
     * @param matchId
     * @returns MatchTypeUnion Successful Response
     * @throws ApiError
     */
    public static unloadMatchBoardMatchesV2UnloadMatchBoardMatchIdPut(
        matchId: number,
    ): CancelablePromise<MatchTypeUnion> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/matches/v2/unload_match_board/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Match Fact
     * @param matchId
     * @returns Translation Successful Response
     * @throws ApiError
     */
    public static getMatchFactMatchesV2MatchFactMatchIdGet(
        matchId: number,
    ): CancelablePromise<Translation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/matches/v2/match_fact/{match_id}',
            path: {
                'match_id': matchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
