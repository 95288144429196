import React, { useCallback, useContext, useEffect, useState } from "react";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ItemBlockHeader from "../../components/Layout/ItemBlockHeader";
import GPTIcon from "../../components/Icons/GPTIcon";
import { translate } from "../../config/translator";
import { ClavaContext } from "../../config/contexts";
import ClavaTextInput from "../../components/Atoms/ClavaTextInput";
import ClavaButton from "../../components/Atoms/ClavaButton";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import ClavaTextArea from "../../components/Atoms/ClavaTextArea";

const GptPage: React.FC = () => {
  const { l } = useContext(ClavaContext);
  const [teamId, setTeamId] = useState<number>();
  const [leagueId, setLeagueId] = useState<number>();
  const [matchDay, setMatchDay] = useState<number>();
  const [temp, setTemp] = useState<number>();
  const [maxTokens, setMaxTokens] = useState<number>();
  const [devData, setDevData] = useState<string>("");
  const [userData, setUserData] = useState<string>();
  const [model, setModel] = useState<string>("");
  const [result, setResult] = useState<string>();
  const [topP, setTopP] = useState<number>();
  const { call, loading } = useServer(false);
  const [amountMatches, setAmountMatches] = useState<number>();
  const setTeamIdCont = useCallback((val: string) => {
    const v = parseInt(val, 10);
    if (!Number.isNaN(v)) {
      setTeamId(v);
    } else setTeamId(undefined);
  }, []);
  const setMatchDayCont = useCallback((val: string) => {
    const v = parseInt(val, 10);
    if (!Number.isNaN(v)) {
      setMatchDay(v);
    } else setMatchDay(undefined);
  }, []);
  const setAmountMatchesCont = useCallback((val: string) => {
    const v = parseInt(val, 10);
    if (!Number.isNaN(v)) {
      setAmountMatches(v);
    } else setAmountMatches(undefined);
  }, []);
  const setLeagueIdCont = useCallback((val: string) => {
    const v = parseInt(val, 10);
    if (!Number.isNaN(v)) {
      setLeagueId(v);
    } else setLeagueId(undefined);
  }, []);
  const setTopPCont = useCallback((val: string) => {
    const v = parseFloat(val);
    setResult(undefined);
    if (!Number.isNaN(v)) {
      setTopP(v);
    } else setTopP(undefined);
  }, []);
  const setMaxTokensCont = useCallback((val: string) => {
    const v = parseInt(val, 10);
    setResult(undefined);
    if (!Number.isNaN(v)) {
      setMaxTokens(v);
    } else setMaxTokens(undefined);
  }, []);
  const setTempCont = useCallback((val: string) => {
    const v = parseFloat(val);
    setResult(undefined);
    if (!Number.isNaN(v)) {
      setTemp(v);
    } else {
      setTemp(undefined);
    }
  }, []);
  useEffect(() => {
    call("getGptAmountMatches", []).then(setAmountMatches);
  }, [call]);
  const onGetData = useCallback(() => {
    if (teamId && matchDay && leagueId && amountMatches)
      call("getGPT", [teamId, matchDay, leagueId, amountMatches]).then((res) => {
        setUserData(res.userData);
        setTemp(res.temp);
        setTopP(res.topP);
        setDevData(res.devData);
        setModel(res.model);
        setResult(undefined);
        setMaxTokens(res.maxTokens);
      });
  }, [teamId, leagueId, matchDay, amountMatches, call]);
  const onDeleteData = useCallback(() => {
    setUserData(undefined);
    setResult(undefined);
  }, []);
  const onManualFact = useCallback(() => {
    if (topP && maxTokens && temp && userData)
      call("gptManualFact", [temp, maxTokens, userData, devData, model, topP]).then(setResult);
  }, [maxTokens, temp, devData, model, topP, userData, call]);
  /* const onJSON = useCallback(() => {
     if (gptData) {

       setUserData(undefined);
       setTimeout(() => {
         setUserData(JSON.stringify(gptData));
       }, 100);
     }
   }, [gptData]);
   const onText = useCallback(() => {
     if (gptData) {
       setUserData(undefined);
       setTimeout(() => {
         let scorers: Record<string, number> = {};
         let cards: Record<string, number> = {};
         let changeIN: Record<string, number> = {};
         let changeOut: Record<string, number> = {};
         const matchesString = gptData.map(m => {
           const events = m.events.map(ev => {
             if (ev.type === "CHANGE") {
               const playerIn = ev.playerIn.familyName + " " + ev.playerIn.givenName + ", " + (ev.playerIn.mainTeamId === m.team1.id ? m.team1.name.textDE : m.team2.name.textDE);
               const player = ev.player.familyName + " " + ev.player.givenName + ", " + (ev.player.mainTeamId === m.team1.id ? m.team1.name.textDE : m.team2.name.textDE);
               if (playerIn in changeIN) {
                 changeIN = {
                   ...changeIN,
                   [playerIn]: changeIN[playerIn] + 1
                 };
               } else {
                 changeIN[playerIn] = 1;
               }
               if (player in changeOut) {
                 changeOut = {
                   ...changeOut,
                   [player]: changeOut[player] + 1
                 };
               } else {
                 changeOut[player] = 1;
               }
               return `${ev.type}: ${ev.playerIn.familyName + " " + ev.playerIn.givenName} für ${ev.player.familyName + " " + ev.player.givenName + ", " + (ev.player.mainTeamId === m.team1.id ? m.team1.name.textDE : m.team2.name.textDE)}`;
             }
             if (ev.type === "CARD") {
               if (ev.player) {
                 const player = ev.player.familyName + " " + ev.player.givenName + ", " + (ev.player.mainTeamId === m.team1.id ? m.team1.name.textDE : m.team2.name.textDE);
                 if (player in cards) {
                   cards = {
                     ...cards,
                     [player]: cards[player] + 1
                   };
                 } else {
                   cards[player] = 1;
                 }
                 return `${ev.type}: ${ev.player.familyName + " " + ev.player.givenName + ", " + (ev.player.mainTeamId === m.team1.id ? m.team1.name.textDE : m.team2.name.textDE)}`;
               }
               return "";
             }
             if (ev.type === "GOAL") {
               if (ev.player) {
                 const player = ev.player.familyName + " " + ev.player.givenName + ", " + (ev.player.mainTeamId === m.team1.id ? m.team1.name.textDE : m.team2.name.textDE);
                 if (player in scorers) {
                   scorers = {
                     ...scorers,
                     [player]: scorers[player] + 1
                   };
                 } else {
                   scorers[player] = 1;
                 }
               }
             }
             return `${ev.type}: ${ev.player ? ev.player.familyName + " " + ev.player.givenName + ", " : ""}${ev.teamId === m.team1.id ? m.team1.name.textDE : m.team2.name.textDE}`;
           });
           return `${m.team1.name.textDE} ${m.goal1} - ${m.goal2} ${m.team2.name.textDE}\n${events.join("\n")}`;
         }).join("\n");
         const scorersString = Object.keys(scorers).map(key => `${key} = ${scorers[key]}`).join("\n");
         const cardsString = Object.keys(cards).map(key => `${key} = ${cards[key]}`).join("\n");
         const changeInString = Object.keys(changeIN).map(key => `${key} = ${changeIN[key]}`).join("\n");
         const changeOutString = Object.keys(changeOut).map(key => `${key} = ${changeOut[key]}`).join("\n");

         setUserData(`${matchesString}\nTore:\n${scorersString}\nKarten:\n${cardsString}\nEinwechsel:\n${changeInString}\nAuswechesl:\n${changeOutString}`);
       }, 100);
     }
   }, [gptData]); */
  return (
    <>

      <Breadcrumps></Breadcrumps>
      <RoundedBlock>
        <ItemBlockHeader CustomIcon={GPTIcon} name={translate("gpt", l)} />
        <h3 className="font-bold text-lg">{translate("requirements", l)}</h3>

        <p>{translate("gptDescription", l)}</p>

        <ul className="ml-4">
          <li className="my-2">
            <b>{translate("temperature", l)}</b>: {translate("tempDescription", l)}
            <ul className="ml-4">
              <li><b>Low Temperature (0.0 - 0.3)</b>: {translate("tempLow", l)}</li>

              <li><b>Medium Temperature (0.4 - 0.7)</b>: {translate("tempMedium", l)}</li>

              <li><b>High Temperature (0.8 - 1.0)</b>: {translate("tempHigh", l)}</li>
            </ul>
            {translate("tempDefault", l)}
          </li>
          <li className="my-2">
            <b>{translate("maxTokensTitle", l)}</b>: {translate("maxTokensDescription", l)}
          </li>

          <li className="my-2">
            <b>{translate("model", l)}</b>: {translate("modelDescription", l)} <a
            href="https://openai.com/api/pricing/"
            target="_blank" rel="noreferrer"
            className="text-primary font-semibold underline">{translate("pricing", l)}</a>
          </li>

          <li className="my-2"><b>TOP_P</b>: {translate("topP", l)}
          </li>
        </ul>
        <br />
        <p>{translate("widthHeight", l)}</p>
        <br />
        <a
          href="https://community.openai.com/t/cheat-sheet-mastering-temperature-and-top-p-in-chatgpt-api/172683"
          target="_blank" rel="noreferrer"
          className="text-primary underline font-semibold">- {translate("cheatSheet", l)}</a>
        {!userData ? (
          <LoadingBlock isLoading={loading}>
            <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
              <ClavaTextInput className="flex-1 mx-2" onChange={setTeamIdCont}
                              value={teamId?.toString(10) ?? ""} type={"number"}
                              label={translate("team", l) + " ID"} />
              <ClavaTextInput className="flex-1 mx-2" onChange={setLeagueIdCont}
                              type={"number"}
                              value={leagueId?.toString(10) ?? ""}
                              label={translate("league", l) + " ID"} />
              <ClavaTextInput className="flex-1 mx-2" onChange={setMatchDayCont}
                              type={"number"}
                              value={matchDay?.toString(10) ?? ""}
                              label={translate("matchDay", l)} />
              <ClavaTextInput className="flex-1 mx-2" onChange={setAmountMatchesCont}
                              type={"number"}
                              value={amountMatches?.toString(10) ?? ""}
                              label={translate("amountMatches", l)} />
            </div>
            <ClavaButton disabled={!matchDay || !teamId || !leagueId || !amountMatches}
                         onClick={onGetData}>{translate("collectData", l)}</ClavaButton>
          </LoadingBlock>) : (
          <LoadingBlock isLoading={loading}>
            <div className="flex flex-row items-start justify-between">
              <ClavaButton onClick={onDeleteData}
                           lightSecondary>{translate("back", l)}</ClavaButton>
            </div>
            <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">
              <ClavaTextInput className="flex-1 mx-2" onChange={setModel}
                              value={model} type={"text"}
                              label={translate("model", l)} />
              <ClavaTextInput className="flex-1 mx-2" onChange={setTopPCont}
                              value={topP?.toString(10) ?? ""} type={"number"}
                              label={"TOP_P"} />
              <ClavaTextInput className="flex-1 mx-2" onChange={setMaxTokensCont}
                              type={"number"}
                              value={maxTokens?.toString(10) ?? ""}
                              label={translate("maxTokensTitle", l)} />
              <ClavaTextInput className="flex-1 mx-2" onChange={setTempCont}
                              type={"number"}
                              value={temp?.toString(10) ?? ""}
                              label={translate("temperature", l)} />
            </div>
            <div className="flex flex-row items-center mx-[-8px] mt-2 mb-4">

              {!!userData ? (<ClavaTextArea className="flex-1 mx-2" onChange={setUserData}
                                            value={userData} rows={20}
                                            label={"User Data"} />) : (
                <div className="flex-1 mx-2" />)}
              <ClavaTextArea className="flex-1 mx-2" onChange={setDevData} rows={20}
                             value={devData}
                             label={"Developer Data"} />
            </div>
            {!!result && (
              <div className="my-4">
                <span className="font-bold text-lg block my-4">{result}</span>
              </div>
            )}

            <ClavaButton className="flex-1 mx-2" onClick={onManualFact}>
              {translate("manualFact", l)}
            </ClavaButton>
          </LoadingBlock>
        )}
      </RoundedBlock>
    </>
  );
};

export default GptPage;