/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Batch } from '../models/Batch';
import type { LLMEnum } from '../models/LLMEnum';
import type { ManualRequest } from '../models/ManualRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GptService {
    /**
     * Get User Content Team Facts
     * @param teamId
     * @param matchDay
     * @param leagueId
     * @param amountMatches
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getUserContentTeamFactsGptUserContentTeamFactsTeamIdGet(
        teamId: number,
        matchDay: number,
        leagueId: number,
        amountMatches: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/user_content_team_facts/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'match_day': matchDay,
                'league_id': leagueId,
                'amount_matches': amountMatches,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Developer Content Team Facts
     * @param teamId
     * @param amountMatches
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getDeveloperContentTeamFactsGptDeveloperContentTeamFactsTeamIdGet(
        teamId: number,
        amountMatches: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/developer_content_team_facts/{team_id}',
            path: {
                'team_id': teamId,
            },
            query: {
                'amount_matches': amountMatches,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get User Content Match Facts
     * @param matchId
     * @param amountMatches
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getUserContentMatchFactsGptUserContentMatchFactsMatchIdGet(
        matchId: number,
        amountMatches: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/user_content_match_facts/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'amount_matches': amountMatches,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Developer Content Match Facts
     * @param matchId
     * @param amountMatches
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getDeveloperContentMatchFactsGptDeveloperContentMatchFactsMatchIdGet(
        matchId: number,
        amountMatches: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/developer_content_match_facts/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'amount_matches': amountMatches,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Temperature
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getTemperatureGptTemperatureGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/temperature',
        });
    }
    /**
     * Get Chat Gpt Model
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getChatGptModelGptChatGptModelGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/chat_gpt_model',
        });
    }
    /**
     * Get Deepseek Model
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getDeepseekModelGptDeepseekModelGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/deepseek_model',
        });
    }
    /**
     * Get Max Tokens
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getMaxTokensGptMaxTokensGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/max_tokens',
        });
    }
    /**
     * Get Top P
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getTopPGptTopPGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/top_p',
        });
    }
    /**
     * Get Chat Model
     * @returns LLMEnum Successful Response
     * @throws ApiError
     */
    public static getChatModelGptGetLlmGet(): CancelablePromise<LLMEnum> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/get_llm',
        });
    }
    /**
     * Get Amount Matches Team Facts
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getAmountMatchesTeamFactsGptAmountMatchesTeamFactsGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/amount_matches_team_facts',
        });
    }
    /**
     * Get Amount Matches Match Facts
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getAmountMatchesMatchFactsGptAmountMatchesMatchFactsGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/amount_matches_match_facts',
        });
    }
    /**
     * Start Process Team Facts
     * @param leagueId
     * @param matchDay
     * @param llm
     * @returns any Successful Response
     * @throws ApiError
     */
    public static startProcessTeamFactsGptTeamFactsLeagueIdMatchDayPost(
        leagueId: number,
        matchDay: number,
        llm: LLMEnum,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/team_facts/{league_id}/{match_day}',
            path: {
                'league_id': leagueId,
                'match_day': matchDay,
            },
            query: {
                'llm': llm,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Start Process Match Facts
     * @param matchId
     * @param llm
     * @returns any Successful Response
     * @throws ApiError
     */
    public static startProcessMatchFactsGptMatchFactsMatchIdPost(
        matchId: number,
        llm: LLMEnum,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/match_facts/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'llm': llm,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Check Process Team Facts
     * @returns any Successful Response
     * @throws ApiError
     */
    public static checkProcessTeamFactsGptCheckTeamFactsPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/check_team_facts',
        });
    }
    /**
     * Check Process Match Facts
     * @returns any Successful Response
     * @throws ApiError
     */
    public static checkProcessMatchFactsGptCheckMatchFactsPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/check_match_facts',
        });
    }
    /**
     * Get Batch Status
     * @param batchId
     * @param llm
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBatchStatusGptBatchStatusBatchIdGet(
        batchId: string,
        llm: LLMEnum,
    ): CancelablePromise<(Batch | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gpt/batch_status/{batch_id}',
            path: {
                'batch_id': batchId,
            },
            query: {
                'llm': llm,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Manual Request
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static manualRequestGptManualRequestPost(
        requestBody: ManualRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gpt/manual_request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
