import React, { useCallback, useContext, useState } from "react";
import { AreaOfInterest, Image, Post } from "../../client/api";
import { faDownload, faTrash } from "@fortawesome/pro-light-svg-icons";
import { TableRowItem, useList } from "../../hooks/useList";
import { ClavaContext } from "../../config/contexts";
import { useNavigate } from "react-router";
import Breadcrumps from "../../components/Layout/Breadcrumps";
import RoundedBlock from "../../components/Atoms/RoundedBlock";
import ListBlockHeader from "../../components/Layout/ListBlockHeader";
import FeedIcon from "../../components/Icons/FeedIcon";
import { TableHeaderItem } from "../../components/Table/TableHeaderItemView";
import useServer from "../../hooks/useServer";
import LoadingBlock from "../../components/Atoms/LoadingBlock";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import MessageBox from "../../components/Atoms/MessageBox";
import { translate } from "../../config/translator";
import TableRow from "../../components/Table/TableRow";
import AoiSelect from "../../components/Atoms/Common/AoiSelect";
import FeedDownloadModal from "./FeedDownloadModal";

const items: TableHeaderItem[] = [
  {
    label: "id"
  },
  {
    label: "content"
  },
  {
    label: "media"
  },
  {
    label: "author"
  },
  {
    label: "likes"
  },
  {
    label: "comments"
  },
  {
    label: "action"
  }
];
const FeedList = () => {
  const { l } = useContext(ClavaContext);
  const navigator = useNavigate();
  const [del, setDel] = useState<Post>();
  const [download, setDownload] = useState<Post>();
  const { call, loading } = useServer(true);
  const closeDownload = useCallback(() => {
    setDownload(undefined);
  }, []);
  const [selectedAoi, setAoi] = useState<AreaOfInterest>();
  const mapper = useCallback((post: Post) => {
    return {
      id: post.id,
      search: (post.content + " " + post.author.username + " " + post.author.familyName + " " + post.author.givenName).toLowerCase(),
      items: [
        { label: post.id },
        {
          label: post.content
        },
        {
          label: `${post.userMedia.filter(p => "url" in p).length} Photos, ${post.userMedia.filter(p => !("url" in p)).length} Video`,
          icon: post.userMedia.map<Image>((
            media
          ) => (
            {
              id: media.id,
              aspectRatio: media.aspectRatio,
              url: "url" in media ? media.url : media.dash.replace("manifest/video.mpd", "thumbnails/thumbnail.jpg"),
              cropScale: media.cropScale,
              cropX: media.cropX,
              cropY: media.cropY,
              cropAspectRatio: media.cropAspectRatio,
              uploadDate: media.uploadDate,
              uploadedById: media.uploadedById
            }
          ))[0]
        },
        {
          buttons: [{
            label: post.author.givenName ? `${post.author.familyName} ${post.author.givenName}` : post.author.username,
            onClick: () => {
              navigator("/user/" + post.author.id);
            }
          }]
        },
        { label: post.likes.length },
        { label: post.amountComments },
        {
          centered: true,
          buttons: [
            {
              icon: faDownload,
              onClick: () => {
                setDownload(post);
              },
              type: "primary"
            },
            {
              icon: faTrash,
              onClick: () => {
                setDel(post);
              },
              type: "danger"
            }
          ]
        }
      ]
    } as TableRowItem;
  }, [navigator]);
  const {
    setItems,
    selected,
    allSelected,
    onCheckAll,
    onCheck,
    sthChanges,
    limit,
    filtered
  } = useList(mapper, 10);
  const toggleDel = useCallback(() => {
    setDel(undefined);
  }, []);

  const onDelete = useCallback(() => {
    if (del) {
      const id = del.id;
      call("deletePost", [id]).then(() => {
        sthChanges(id);
        setDel(undefined);
      });
    }
  }, [del, call, sthChanges]);
  const onSearch = useCallback((off: number, lim: number) => {

    return call("getFeed", [selectedAoi?.id ?? 1, off, lim]);

  }, [call, selectedAoi]);
  useInfiniteScroll(onSearch, setItems, 0, limit);
  return (
    <>
      <Breadcrumps />
      <RoundedBlock>
        <ListBlockHeader title={"feed"} icon={<FeedIcon />}>
          <AoiSelect selected={selectedAoi} setSelected={setAoi} autoSelect />
        </ListBlockHeader>

        <LoadingBlock isLoading={loading} smallLoading={filtered.length !== 0}>
          <Table>

            <TableHeader items={items} onCheckAll={onCheckAll}
                         allChecked={allSelected} />
            <tbody>
            {filtered.map(fil => (
              <TableRow id={fil.id} items={fil.items} onCheck={onCheck}
                        key={"post-list-" + fil.id}
                        checked={selected?.includes(fil.id)} />
            ))}
            </tbody>
          </Table>
        </LoadingBlock>
      </RoundedBlock>
      {!!download && (
        <FeedDownloadModal post={download} close={closeDownload} />
      )}
      <MessageBox type={"danger"} open={!!del} toggle={toggleDel}
                  msg={translate("sureDelete", l, { "[name]": del ? del.id.toString(10) : "" })}
                  btn1Clb={onDelete} btn1Text={translate("yes", l)} btn2Clb={toggleDel}
                  btn2Text={translate("no", l)} />
    </>
  );
};

export default FeedList;
