import {
  AdPositionEnum,
  LanguageLocaleEnum,
  LeagueCategoryEnum,
  LeagueRatingEnum,
  PlayerPositionEnum,
  SportEnum
} from "../client/api";
import { ClavaSelectItems } from "./types";
import { translate } from "./translator";

export const APP_STORE_URL = "https://clavasports.app.link/download";
export const IOS_STORE_URL =
  "https://apps.apple.com/us/app/clava-sports/id1637227932";
export const ANDROID_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.clava";

export const AMOUNT_MATCHDAYS = 30;

export const LEAGUE_CATEGORIES: LeagueCategoryEnum[] = [
  LeagueCategoryEnum.LEAGUE,
  LeagueCategoryEnum.WOMEN,
  LeagueCategoryEnum.YOUTH,
  LeagueCategoryEnum.CUP,
  LeagueCategoryEnum.VSS_YOUTH,
  LeagueCategoryEnum.VSS_FREE_TIME,
  LeagueCategoryEnum.VIENNA
];
export const PLAYER_POSITION_SORT_DIRECTION: PlayerPositionEnum[] = [
  PlayerPositionEnum.KEEPER,
  PlayerPositionEnum.DEFENDER,
  PlayerPositionEnum.MIDFIELD,
  PlayerPositionEnum.OFFENSE
];
export const PROD_ENDPOINT = "api.clava-sports.com";
export const DEV_ENDPOINT = "api.dev.clava-sports.com";
export const STAG_ENDPOINT = "api.stag.clava-sports.com";
export const BETA_ENDPOINT = "api.beta.clava-sports.com";

export const PROD_SOCKET_ENDPOINT = "socket.clava-sports.com";
export const DEV_SOCKET_ENDPOINT = "socket.dev.clava-sports.com";
export const BETA_SOCKET_ENDPOINT = "socket.beta.clava-sports.com";
export const STAG_SOCKET_ENDPOINT = "socket.stag.clava-sports.com";

export const AS_FIRST_OPEN = "clava-f-o";
export const AS_LANG = "clava-lang-new";
export const AS_THEME = "clava-theme";
export const AS_ENDPOINT = "clava-endpoint";
export const AS_ADMIN_KEY = "clava-admin-key";
export const AS_THEME_VAL_DARK = "dark";
export const AS_THEME_VAL_LIGHT = "light";
export const AS_SPORTS_TYPE = "clavaSelectedSportsType";
export const AS_FILTER_AOI = "clavaSelectedSportsType";

export const AS_AOI = "areaOfInterest";
export const AS_SEASON = "season";
export const AS_FAVORITES = "favorites";
export const AS_CUSTOM_USERNAME = "customUsername";
export const AS_MEDIATHEK = "clava-mediathek";
export const FATTURE_IN_CLOUD_ID = "9879";
export const FATTURE_IN_CLOUD_CLIENT_ID = "rdpcBiKxKoeYCSNriWh3N3DguxF0aefe";
export const FATTURE_IN_CLOUD_SECRED = "lkKBxs5XpeYXYZJA9fSVuAJjTXAGeRAeNwwyGVK3rVtKaNQnTbnthiUOU4WjjB4l";
export const AS_TASK_STORAGE = "clava-task-storage";
export const DefaultFadeTrans = {
  timeout: 250,
  baseClass: "default-modal"
};
export const AGB_LEVEL = "v1.0";

export const serverItems: ClavaSelectItems[] = [
  {
    key: PROD_ENDPOINT,
    label: "Production"
  },
  {
    key: STAG_ENDPOINT,
    label: "Staging"
  },
  {
    key: BETA_ENDPOINT,
    label: "Beta"
  },
  {
    key: DEV_ENDPOINT,
    label: "Development"
  }
];

export const tableRatingItems: ClavaSelectItems[] = [LeagueRatingEnum.SOUTHTYROL, LeagueRatingEnum.TRENTINO, LeagueRatingEnum.HOCKEY, LeagueRatingEnum.HOCKEY_RITTEN].map((st) => ({
  key: st,
  label: st
}));
export const SPORTS = [SportEnum.SOCCER, SportEnum.HOCKEY, SportEnum.VOLLEYBALL];
export const adPositionItems: (l: LanguageLocaleEnum) => ClavaSelectItems[] = (l) => {
  return [
    {
      key: AdPositionEnum.HOME_MATCH,
      label: translate(AdPositionEnum.HOME_MATCH, l)
    },
    {
      key: AdPositionEnum.HOME_MATCH_DOUBLE,
      label: translate(AdPositionEnum.HOME_MATCH_DOUBLE, l)
    },
    {
      key: AdPositionEnum.ADMOB_PLACEHOLDER,
      label: translate(AdPositionEnum.ADMOB_PLACEHOLDER, l)
    },
    {
      key: AdPositionEnum.MATCH_HISTORY_BOTTOM,
      label: translate(AdPositionEnum.MATCH_HISTORY_BOTTOM, l)
    },
    {
      key: AdPositionEnum.LEAGUE_MATCH_MATCH,
      label: translate(AdPositionEnum.LEAGUE_MATCH_MATCH, l)
    },
    {
      key: AdPositionEnum.USER_FEED,
      label: translate(AdPositionEnum.USER_FEED, l)
    },
    {
      key: AdPositionEnum.TEAM_OF_THE_WEEK,
      label: translate(AdPositionEnum.TEAM_OF_THE_WEEK, l)
    },
    {
      key: AdPositionEnum.STANDINGS,
      label: translate(AdPositionEnum.STANDINGS, l)
    },
    {
      key: AdPositionEnum.NEWS_ADS,
      label: translate(AdPositionEnum.NEWS_ADS, l)
    }
  ];
};
