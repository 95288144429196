/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveTasksResponse } from '../models/ActiveTasksResponse';
import type { Task } from '../models/Task';
import type { TaskInfo } from '../models/TaskInfo';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TaskService {
    /**
     * Get Task
     * @param taskId
     * @returns TaskInfo Successful Response
     * @throws ApiError
     */
    public static getTaskTaskPost(
        taskId: string,
    ): CancelablePromise<TaskInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/',
            query: {
                'task_id': taskId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Active Tasks
     * Returns a dictionary of active tasks per worker.
     * @returns ActiveTasksResponse Successful Response
     * @throws ApiError
     */
    public static getActiveTasksTaskActiveTasksGet(): CancelablePromise<ActiveTasksResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/task/active_tasks',
        });
    }
    /**
     * @deprecated
     * Update All Statistics
     * @param areaOfInterestId
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static updateAllStatisticsTaskUpdateAllStatisticsPost(
        areaOfInterestId?: number,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/update_all_statistics',
            query: {
                'area_of_interest_id': areaOfInterestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * @deprecated
     * Update All Standings
     * @param areaOfInterestId
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static updateAllStandingsTaskUpdateAllStandingsPost(
        areaOfInterestId?: number,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/update_all_standings',
            query: {
                'area_of_interest_id': areaOfInterestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Calculate Team Of The Week
     * @param areaOfInterestId
     * @param leagueId
     * @param matchDay
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static calculateTeamOfTheWeekTaskCalculateTeamOfTheWeekPost(
        areaOfInterestId?: number,
        leagueId?: number,
        matchDay?: number,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/calculate_team_of_the_week',
            query: {
                'area_of_interest_id': areaOfInterestId,
                'league_id': leagueId,
                'match_day': matchDay,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Calculate Player Ratings
     * @param areaOfInterestId
     * @param leagueId
     * @param matchDay
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static calculatePlayerRatingsTaskCalculatePlayerRatingsPost(
        areaOfInterestId?: number,
        leagueId?: number,
        matchDay?: number,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/calculate_player_ratings',
            query: {
                'area_of_interest_id': areaOfInterestId,
                'league_id': leagueId,
                'match_day': matchDay,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Update Hubspot
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static updateHubspotTaskUpdateHubspotPost(): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/update_hubspot',
        });
    }
    /**
     * Calculate Insider Score
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static calculateInsiderScoreTaskCalculateInsiderScorePost(): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/calculate_insider_score',
        });
    }
    /**
     * Calculate Inactive Player Statistics League
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static calculateInactivePlayerStatisticsLeagueTaskCalculateInactivePlayerStatisticsLeaguePost(): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/calculate_inactive_player_statistics_league',
        });
    }
    /**
     * Refresh Team Facts
     * @param areaOfInterestId
     * @returns Task Successful Response
     * @throws ApiError
     */
    public static refreshTeamFactsTaskRefreshTeamFactsPost(
        areaOfInterestId?: number,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/task/refresh_team_facts',
            query: {
                'area_of_interest_id': areaOfInterestId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
