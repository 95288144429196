/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogResponse } from '../models/LogResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuditLogService {
    /**
     * Get Description
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getDescriptionAuditLogDescriptionGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audit_log/description',
        });
    }
    /**
     * Query Logs
     * @param userId
     * @param method
     * @param action
     * @param page
     * @param pageSize
     * @returns LogResponse Successful Response
     * @throws ApiError
     */
    public static queryLogsAuditLogGet(
        userId?: (number | null),
        method?: ('POST' | 'PUT' | 'PATCH' | 'DELETE' | null),
        action?: (string | null),
        page: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<LogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audit_log/',
            query: {
                'userId': userId,
                'method': method,
                'action': action,
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Audit Logs By User
     * @param userId
     * @param page
     * @param pageSize
     * @returns LogResponse Successful Response
     * @throws ApiError
     */
    public static auditLogsByUserAuditLogUserUserIdGet(
        userId: number,
        page: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<LogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audit_log/user/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Audit Logs By Match
     * @param matchId
     * @param page
     * @param pageSize
     * @returns LogResponse Successful Response
     * @throws ApiError
     */
    public static auditLogsByMatchAuditLogMatchMatchIdGet(
        matchId: number,
        page: number = 1,
        pageSize: number = 10,
    ): CancelablePromise<LogResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/audit_log/match/{match_id}',
            path: {
                'match_id': matchId,
            },
            query: {
                'page': page,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
